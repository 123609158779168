<template>
    <div class="h-full overflow-auto">
        <div 
            v-if="activeMain"
            class="container mx-auto flex mt-12"> 
            <!-- max-w-lg -->
            
            <!-- Left navigation -->
            <div class="w-1/4 pr-12">
                
                <!-- Section loop -->
                <div 
                    v-for="(step, index) in steps"
                    @mouseover="mouseoverStep = step"
                    @mouseleave="mouseoverStep = null"
                    class="mb-8">
                    
                    <div
                        class="cursor-pointer flex"
                        @click="setActiveMain(step)">
                        
                        <div
                            class="w-10 h-10 rounded-full flex items-center justify-center p-1 text-lo-green bg-white mr-2"
                            :class="
                                [
                                    mouseoverStep && mouseoverStep == step ? '' : '',
                                    activeMain.id == step.id ? '' : ''
                                ]
                            ">
                            
                            <div class="w-4 h-4 text-center -mt-1">
                                <div v-if="step.menu_order == 1" v-html="number1" style="text-indent: -1px;"></div>
                                <div v-else-if="step.menu_order == 2" v-html="number2"></div>
                                <div v-else-if="step.menu_order == 3" v-html="number3"></div>
                                <div v-else-if="step.menu_order == 4" v-html="number4"></div>
                                <div v-else-if="step.menu_order == 5" v-html="star"></div>
                            </div>
                        </div>
                        <div 
                            class="py-2 uppercase text-lo-green-darker font-bold mt-1 text-lg"
                            :class="activeMain.id == step.id ? '' : ''">
                            
                            <span v-html="step.title.rendered"></span>
                        </div>
                    </div>
                    
                    <div 
                        v-for="subStep in step.steps"
                        @click="setActiveMain(step, subStep); setActiveSub(subStep)"
                        class="cursor-pointer flex items-start mt-1 mb-2 rounded-r-full">
                        
                        <div 
                            v-if="!subStepStatus(subStep)"
                            class="flex-none ml-12 mr-2 w-5 h-5 rounded text-white flex items-center justify-center border"
                            :class="activeSub && subStep.id == activeSub.id ? 'bg-lo-pink border-lo-pink' : 'bg-lo-green border-lo-green-lighter'">
                            <!-- DONE -->
                            <i 
                                class="fas fa-check"
                                style="font-size: 10px; margin-left: 2px;"
                            />
                        </div>
                        <div 
                            v-else
                            class="flex-none ml-12 mr-2 w-5 h-5 rounded text-white flex items-center justify-center border"
                            :class="activeSub && subStep.id == activeSub.id ? 'bg-white border-lo-pink' : 'bg-white border-lo-green-lighter'">
                            <!-- NOT DONE -->
                        </div>
                        
                        <div 
                            class="flex-grow text-lo-green-darker"
                            :class="[
                                activeSub && subStep.id == activeSub.id ? 'text-lo-pink' : 'text-lo-green-darker'
                            ]
                            ">
                            
                            <span v-html="subStep.acf['title-short'] || subStep.title.rendered"></span>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Main content -->
            <div class="w-2/4 main-content">
                <div v-if="activeMain.menu_order != 5">
                    <div 
                        class="flex mb-12" 
                        v-if="activeMain.steps[0] == activeSub">
                        
                        <div 
                            class="flex-none rounded-full flex items-center justify-center bg-lo-green-dark mr-12"
                            style="width: 145px; height: 145px;">
                            
                            <div 
                                class="text-center text-lo-green-darkest"
                                style="width: 60px; height: 80px;">
                                
                                <div v-if="activeMain.menu_order == 1" v-html="number1" style="text-indent: 5px;"></div>
                                <div v-else-if="activeMain.menu_order == 2" v-html="number2"></div>
                                <div v-else-if="activeMain.menu_order == 3" v-html="number3"></div>
                                <div v-else-if="activeMain.menu_order == 4" v-html="number4"></div>
                            </div>
                        </div>
                        <h1 
                            class="flex-grow  "
                            v-html="activeMain.content.rendered" 
                        />
                    </div>
                    
                    <div class="mb-4 overflow-hidden">
                        <h2 class="text-lo-pink">
                            <span v-html="activeSub.title.rendered"></span>
                        </h2>
                        <div v-html="activeSub.content.rendered"></div>
                    </div>
                    
                    <div 
                        v-for="(step, index) in activeSub.steps"
                        class="mb-8 bg-white relative">
                        
                        <header 
                            class="flex items-center bg-lo-green-lightest relative"
                            style="box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.1);">
                            
                            <div 
                                @click="toggleExpanded(step, index)"
                                class="p-6 flex-grow cursor-pointer">
                                <span v-html="step.title.rendered"></span>
                            </div>
                            
                            <div class="m-6 flex-none ml-4">
                                
                                
                                <div v-if="expanded.find( id => id == step.id)">
                                    <!-- expanded! -->
                                    <div 
                                        v-if="!step.status"
                                        @click="
                                            step.status = 'closed'; 
                                            saveSteps();
                                            toggleExpanded(step, index);
                                        "
                                        class="button locked h-10 flex items-center items-justify"
                                        style="width: 8rem"
                                        title="Svaret er ikke låst. Låse svaret?">
                                        
                                        <div class="w-3 h-3 flex-none -mt-1 mr-2" v-html="locked"></div>
                                        <div class="flex-grow font-bold" >Lås svar</div>
                                    </div>
                                    <div 
                                        v-else
                                        @click="
                                            step.status = null;
                                            saveSteps();
                                        "
                                        class="button unlocked h-10 flex items-center items-justify"
                                        style="width: 8rem"
                                        title="Svaret er låst. Åpne svaret?">
                                        
                                        <div class="w-4 h-4 flex-none mr-2" v-html="unlocked"></div>
                                        <div class="flex-grow font-bold" >Åpne svar</div>
                                    </div>
                                </div>
                                <div 
                                    v-else
                                    class="flex items-center">
                                    <!-- not expanded! -->
                                    
                                    <div class="w-6 h-6 mr-4">
                                        <div
                                            v-if="step.flagged"
                                            class="text-lo-pink" 
                                            v-html="flag" 
                                            title="Svaret er flagget for uenighet"
                                        />
                                    </div>
                                    
                                    <div 
                                        v-if="!step.status"
                                        @click="
                                            step.status = 'closed'; 
                                            saveSteps();
                                            toggleExpanded(step, index);
                                        "
                                        class="button unlocked w-10 h-10 flex items-center justify-center"
                                        title="Svaret er ikke låst. Låse svaret?">
                                        
                                        <div class="w-3 h-4 flex-none" v-html="unlocked"></div>
                                    </div>
                                    <div 
                                        v-else
                                        @click="
                                            step.status = null; 
                                            saveSteps();
                                            toggleExpanded(step, index);
                                        "
                                        class="button locked small w-10 h-10 flex items-center justify-center"
                                        style="padding: 0;"
                                        title="Svaret er låst. Åpne svaret?">
                                        
                                        <div class="w-3 h-3 -mt-1 flex-none" v-html="locked"></div>
                                    </div>
                                </div>
                                
                            </div>
                        </header>
                        
                        <div 
                            class="m-0 p-0 flex-col"
                            :class="expanded.find( id => id == step.id) ? 'flex ' : 'hidden '">
                            
                            <div 
                                v-for="(f,k) in step.acf"
                                v-if="f && f != ''"
                                class="absolute top-0 right-0"
                                style="margin-right: -12rem; width: 10rem;">
                                
                                <div class="bg-white p-6 rounded-xl relative text-xs mb-4">
                                    <div 
                                        class="no-block"
                                        v-html="f">
                                        <!-- renders the paragraph block -->
                                    </div>
                                    
                                    <div 
                                        class="absolute botom-0 -mb-4" 
                                        style="
                                            width: 0; 
                                            height: 0; 
                                            border-top: 20px solid transparent;
                                            border-bottom: 20px solid transparent;
                                          "
                                          :style="k.includes('green') ? 'border-left: 20px solid white' : 'border-right: 20px solid white'"
                                          :class="k.includes('green') ? 'right-0 mr-10' : 'left-0 ml-10'">
                                    </div>
                                </div>
                                
                                <img 
                                    class="ml-4" 
                                    style="width: 80px;"
                                    :src="`pippip/${k}.svg`"
                                    :title="k"
                                />
                            </div>
                            
                            <form class="flex flex-col">
                                <textarea-autosize
                                    @blur.native="saveSteps"
                                    :disabled="step.status ? 'complete' : false"
                                    placeholder="Svar her"
                                    v-model="step.answer"
                                    ref="steptextarea"
                                    :min-height="200"
                                    :max-height="500"
                                    class="border-0 px-6 py-8"
                                    :class="step.status ? 'cursor-not-allowed': ''"
                                    :title="step.status ? 'Åpne svaret for å redigere testen': ''"
                                />
                            </form>
                            
                            <div 
                                class="w-6 h-6 m-8 absolute bottom-0 right-0"
                                @click="
                                    toggleFlagged(step);
                                    toggleExpanded(step, index); 
                                    toggleExpanded(step, index);
                                ">
                                
                                <div
                                    v-if="step.flagged"
                                    class="text-lo-pink" 
                                    v-html="flag" 
                                    title="Svaret er flagget for uenighet"
                                />
                                <div
                                    v-else
                                    class="text-lo-green-darker" 
                                    v-html="flag" 
                                    title="Flagg dette svaret for uenighet"
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div 
                        v-if="activeMain"
                        class="flex justify-end mb-12">
                        
                        <div v-if="activeMain.steps[ activeSub.menu_order ]">
                            <a 
                                @click="setActiveSub( activeMain.steps[ activeSub.menu_order ] )"
                                v-if="activeMain.steps[ activeSub.menu_order ]"
                                class="button submit">
                                
                                NESTE > {{activeMain.steps[ activeSub.menu_order ].acf['title-short'] || activeMain.steps[ activeSub.menu_order ].title.rendered}}
                            </a>
                            <a 
                                v-else
                                @click="setActiveMain( steps[activeMain.menu_order])"
                                class="button submit" >
                                
                                NESTE > {{steps[activeMain.menu_order].steps[0].acf['title-short'] || steps[activeMain.menu_order].steps[0].title.rendered}}
                            </a>
                        </div>
                        
                    </div>
                </div>
                <div v-else>
                    
                    <header class="w-4/5 mb-12">
                        <div class="flex items-end mb-4">
                            <div class="flex-none mr-12">
                                <img 
                                    class="h-48 object-contain" 
                                    src="pippip/fullfort.svg" 
                                />
                            </div>
                            <div class="flex-grow">
                                <h1>Gratulerer med å ha gjennomført partsdialog om digital endring!</h1>
                            </div>
                        </div>
                        
                        <div 
                            v-html="activeMain.content.rendered">
                        </div>
                        
                        <a 
                            @click="print()"
                            class="text-lo-pink text-lg font-bold cursor-pointer mb-8 inline-block">
                            <!-- Last ned en kopi av prosessbeskrivelsen deres! -->
                            Last ned eller skriv ut en kopi av prosessbeskrivelsen deres her
                        </a>
                        
                        <!-- <p>
                            Hvordan opplevde du å bruke verktøyet? Hjelp oss å bli bedre ved å svare på <a class="text-lo-pink" href="https://docs.google.com/forms/d/e/1FAIpQLSfOLLfhePqY2-Rf2B7FEc0WXddYb2XBGDnUJnDPNqZf3ouXQg/viewform?usp=sf_link" target="_blank">noen få spørsmål</a>.
                        </p> -->
                    </header>
                    
                    <div id="report">
                        <div 
                            style="
                                width: 100vw;
                                height: 100vh;
                                page-break-after: always;
                                text-align: center;
                                line-height: 100%;
                            "
                            class="no-screen bg-lo-green-lighter">
                            
                            <div class="pt-32">
                                <img 
                                    src="logo.svg" 
                                    class="h-32 mb-8 mx-auto"
                                />
                                <h1 class="text-lo-pink">Partsdialog om digital endring</h1>
                            </div>
                        </div>
                        
                        <div 
                            v-for="(mainStep, index) in steps"
                            v-if="index != 4"
                            class="bg-white p-8 mb-8 shadow">
                            
                            <div class="text-2xl page-break">
                                {{index+1}}.
                                <span v-html="mainStep.title.rendered"></span>
                            </div>
                            
                            <div 
                                v-for="subStep in mainStep.steps"
                                class="mt-4 mb-12"
                                style="page-break-inside: avoid;">
                                
                                <h3 class="text-lo-green-darkest" v-html="subStep.title.rendered"></h3>
                                
                                <div 
                                    v-for="step in subStep.steps"
                                    class="mt-4 mb-12 flex items-center"
                                    style="page-break-inside: avoid;">
                                    
                                    <div class="flex-grow pr-10">
                                        <div class="text-grey-dark " v-html="step.title.rendered"></div>
                                        
                                        <div v-if="step.status">
                                            <em class="text-grey-darkest" v-html="step.answer"></em>
                                        </div>
                                        <div v-else>
                                            <em class="text-red">Ikke besvart</em>
                                        </div>
                                    </div>
                                    
                                    <div class="w-6 h-6 flex-none mx-8">
                                        <div
                                            v-if="step.flagged"
                                            class="text-lo-pink" 
                                            v-html="flag" 
                                            title="Svaret er flagget for uenighet"
                                        />
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                        
                    </div>
                    
                </div>
            </div>
            
            <div class="w-1/4">
                <!-- placeholder -->
            </div>
        </div>
        
        
        
        
        <!-- 
        <div class="flex">
            
            <div class="bg-black text-yellow w-1/3">
                steps:<br>
                <pre>{{steps}}</pre>
            </div>
            
            <div class="bg-black text-yellow w-1/3">
                $store.state.form:<br>
                <pre>{{$store.state.form}}</pre>
            </div>
            
            <div class="bg-black text-yellow w-1/3">
                steps:<br>
                <pre>{{steps}}</pre>
            </div>
            
        </div>
         -->
        
        <Footer />
    </div>
</template>

<script>
    export default {
        components: {
            'Footer': () => import('@/components/Footer.vue'),
        },
        
        data(){
            return {
                activeMain: null,
                activeSub: null,
                expanded: [],
                mouseoverStep: null,
                steps: null,
            }
        },
        
        methods: {
            sortSteps(pages){
                try {
                    const stepsTemp = pages;
                    
                    const steps = stepsTemp.filter( step1 => {
                        // step1 =  PAGE ID # 10 (Veileder)
                        if (step1.parent == 10) {
                            
                            // step2 = find pages with parent that is = step1.parent
                            step1.steps = stepsTemp.filter( step2 => {
                                if (step2.parent == step1.id) {
                                    
                                    // step2 = find pages with parent that is = step1.parent
                                    step2.steps = stepsTemp.filter( step3 => {
                                        if (step3.parent == step2.id) {
                                            step3.answer = null;
                                            step3.status = null;
                                            step3.flagged = null;
                                            return step3
                                        }
                                    })
                                    
                                    return step2
                                }
                            })
                            
                            return step1
                        }
                    });
                    
                    // console.log('steps', steps);
                    this.steps = steps;
                }
                catch (error) {
                    console.error(error);
                }
            },
            
            setActiveMain(step, subStep){
                if (step.menu_order == 5) {
                    this.$confetti.start();
                    
                    setTimeout( () => {
                        this.$confetti.stop();
                    }, 3000);
                }
                
                this.activeMain = step;
                
                if (!subStep) {
                    this.activeSub = null;
                    if (step.steps && step.steps.length) {
                        this.setActiveSub( step.steps[0] );
                    }
                }
                
                this.expanded = [];
                if (step.steps.length) {
                    this.expanded.push( step.steps[0].steps[0].id );
                }
            },
            
            setActiveSub(step){
                this.activeSub = step;
                // console.log('setActiveSub', this.activeSub);
                // this.activeSub = step.steps[0];
                this.expanded = [];
                
                if (step.steps && step.steps.length) {
                    this.expanded.push( step.steps[0].id ); // Skal det første spøresmålet velges ved hoved-stegs-bytte?
                }
            },
            
            toggleFlagged(step){
                step.flagged = !step.flagged; 
                this.saveSteps();
            },
            
            toggleExpanded(step, index){
                if ( this.expanded.find( id => id == step.id) ) {
                    // this.expanded = this.expanded.filter( id => id != step.id );
                    this.expanded = [];
                }
                else {
                    this.expanded = [];
                    this.expanded.push(step.id);
                    this.$nextTick(() => {
                        let textarea = this.$refs.steptextarea[index].$el;
                        textarea.focus();
                    });
                }
            },
            
            mainStepStatus(step){
                if (!step.steps) {
                    return
                }
                
                let allCompleted = 0;
                
                step.steps.forEach( subStep => {
                    const stepsNotCompleted = subStep.steps.filter( s => !s.status || s.status == 'draft');
                    allCompleted = allCompleted+stepsNotCompleted.length;
                });
                
                if (allCompleted == 0) {
                    return true
                }
            },
            
            subStepStatus(step) {
                // if all steps are either closed or disagree
                const stepsNotCompleted = step.steps.filter( s => !s.status || s.status == 'draft');
                return stepsNotCompleted.length;
            },
            
            async saveSteps(){
                let stepsToSave = [];
                
                this.steps.forEach( mainStep => {
                    if (mainStep.steps) {
                        mainStep.steps.forEach( subSteps => {
                            if (subSteps.steps) {
                                subSteps.steps.forEach( s => {
                                    if (
                                        (s.answer && s.answer != '') || 
                                        s.flagged ||
                                        s.status == 'closed' ){
                                        
                                        const step = {
                                            id: s.id,
                                            answer: s.answer,
                                            flagged: s.flagged,
                                            status: s.status,
                                        }
                                        stepsToSave.push(step);
                                    }
                                });
                            }
                        });
                    }
                });
                
                // console.log('stepsToSave', stepsToSave);
                this.$store.state.form = stepsToSave;
                
                try {
                    const data = {
                        id: this.$store.state.id,
                        task: 'saveSession',
                        form: this.$store.state.form,
                    };
                    
                    const response = await this.$axios.post( this.backendApiEndpoint , data);
                    // console.log(response.data);
                }
                catch (error) {
                    console.error(error);
                }
            },
            
            print() {
                // delete if exist
                if (document.getElementById('reportClone')) {
                    document.getElementById('reportClone').remove();
                }
                
                // clone 
                const clonedElement = document.getElementById('report').cloneNode(true);
                clonedElement.setAttribute('class', 'no-screen');
                clonedElement.setAttribute('id', 'reportClone');
                document.body.appendChild( clonedElement );
                
                // open print dialog
                window.print();
            }
        },
        
        beforeDestroy () {
            this.$events.off('logoClicked');
            
        },
        
        async mounted(){
            // await this.fetchSteps();
            if ( !this.$store.state.pages ) {
                this.$store.state.pages = await this.fetchPages();
            }
            
            this.sortSteps(this.$store.state.pages);
            
            
            // apply the server side saved form
            if ( 
                this.$store.state.form && 
                this.$store.state.form.length > 0 ) {
                    
                this.steps.forEach( mainStep => {
                    if (mainStep.steps) {
                        mainStep.steps.forEach( subSteps => {
                            if (subSteps.steps) {
                                subSteps.steps.forEach( s => {
                                    const fS = this.$store.state.form.find( fS => fS.id == s.id );
                                    if ( fS ) {
                                        s = Object.assign(s, fS);
                                    }
                                });
                            }
                        });
                    }
                });
            }
                
            this.setActiveMain( this.steps[0] );
            
			this.$events.on('logoClicked',  () => {
                if (! confirm('Veilederen du forlater kan du finne tilbake til med lenke i tidligere mottatt e-post. Er du sikker på at du vil forlate denne veilederen?')) return;
                this.$router.push({ name: 'hjem'})
			});
        },
    }
</script>
